import MuiTreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { api } from '@/api/base';
import store from '@/reducers';
import { CircularProgress } from '@mui/material';

export function ConversionStatus({ node }) {
  const [converted, setConverted] = useState('checking');

  useEffect(() => {
    const checkReady = async () => {
      const formData = new FormData();
      const [experimentName, fileName] = node.id.split('/');
      formData.append('experiment_name', experimentName);
      formData.append('file_name', fileName);

      try {
        const state = store.getState();

        let response = await api.post('image/tile/convert_status', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods':
              'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers':
              'Origin, Content-Type, X-Auth-Token',
            'Content-Type': 'application/json',
            Authorization: state.auth.tokenType + ' ' + state.auth.token,
          },
        });
        if (response.data.ready) {
          setConverted('converted');
        } else {
          setConverted('optimizing');
        }
      } catch (error) {}
    };

    if (converted === 'checking') {
      checkReady();

      // Check the status every 5 seconds
      const intervalId = setInterval(checkReady, 5000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [converted]);

  return (
    <div style={{ color: 'gray' }}>
      {converted === 'checking' ? (
        <CircularProgress size={14} />
      ) : converted === 'optimizing' ? (
        'Optimizing ...'
      ) : (
        ''
      )}
    </div>
  );
}

const TreeView = ({ nodes, selected, onNodeSelect, experiments = false }) => {
  const renderTree = (node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      label={
        <Typography
          sx={{
            //textOverflow: 'ellipsis',
            overflow: 'hidden',
            //whiteSpace: 'nowrap',
            wordWrap: 'break-word',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{node.label.replaceAll('_', ' ')}</span>
            {experiments && node.type === 'file' && (
              <ConversionStatus node={node} />
            )}
          </div>
        </Typography>
      }
      endIcon={
        selected.includes(node.id) ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((child) => renderTree(child))
        : null}
    </TreeItem>
  );

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={onNodeSelect}
      multiSelect
    >
      {nodes.map((node) => renderTree(node))}
    </MuiTreeView>
  );
};
export default TreeView;
