import store from '@/reducers';
import mainApiService from '@/services/mainApiService';

const downloadQueue = [];
let activeWorkers = 0;
const maxWorkers = 50;
const maxRetries = 3; // Maximum retry attempts for failed downloads

const processQueue = async () => {
  while (activeWorkers < maxWorkers && downloadQueue.length > 0) {
    const { path, resolve, reject, attempts } = downloadQueue.shift();
    activeWorkers++;

    // Start the download
    (async () => {
      try {
        const userId = store.getState().auth.user._id;
        const blob = await mainApiService.get(`/static/${userId}/${path}`, {
          responseType: 'blob',
        });
        const file = new File([blob], path, { type: 'image/tiff' });
        file.path = path;
        resolve(file);
      } catch (error) {
        if (attempts < maxRetries) {
          // If the max retries haven't been reached, re-add to the queue
          downloadQueue.push({ path, resolve, reject, attempts: attempts + 1 });
        } else {
          reject(error); // Reject the promise if max retries exceeded
        }
      } finally {
        activeWorkers--;
        processQueue(); // Check for more work after finishing this one
      }
    })();
  }
};

export const getImageByPath = (path) => {
  return new Promise((resolve, reject) => {
    downloadQueue.push({ path, resolve, reject, attempts: 0 });
    processQueue(); // Start processing the queue
  });
};

export const getFoucsStackedImage = async (images, onUploadProgress) => {
  const formData = new FormData();
  images.forEach((file) => formData.append('imageFiles', file));
  return mainApiService.post('image/tile/focus-stack', formData, {
    onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getSuperResoutionImage = async (
  experiment,
  filename,
  scale = 4,
) => {
  return mainApiService.get(
    `image/tile/super-resolution/${experiment}/${filename}/${scale}`,
  );
};

export const getMeasureImage = async (paths) => {
  let contents = [];
  for (let i = 0; i < paths.length; i++) {
    let path = paths[i];
    if (path.indexOf('path=') >= 0) {
      path = path.substr(path.indexOf('path=') + 5);
    }
    if (path[0] === '/') {
      path = path.substr(1);
    }
    const blob = await mainApiService.get(`/static/${path}`, {
      responseType: 'blob',
    });
    const file = new File([blob], path, { type: 'image/tiff' });
    file.path = path;
    contents.push(file);
  }
  return contents;
};
