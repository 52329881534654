import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Box, Tab, Tabs } from '@mui/material';

const BasicML = () => {
  return <div></div>;
};

export default BasicML;
