export const CellPaintingMeasureItems = [
  'Cells_AreaShape_Area',
  'Cells_AreaShape_Center_X',
  'Cells_AreaShape_Center_Y',
  'Cells_AreaShape_Center_Z',
  'Cells_AreaShape_Compactness',
  'Cells_AreaShape_Eccentricity',
  'Cells_AreaShape_EulerNumbery',
  'Cells_AreaShape_Extentumbery',
  'Cells_AreaShape_FormFactorry',
  'Cells_AreaShape_MajorAxisLength',
  'Cells_AreaShape_MaxFeretDiameter',
  'Cells_AreaShape_MaximumRadiuster',
  'Cells_AreaShape_MeanRadiusiuster',
  'Cells_AreaShape_MedianRadiusster',
  'Cells_AreaShape_MinFeretDiameter',
  'Cells_AreaShape_MinorAxisLength',
  'Cells_AreaShape_Orientation',
  'Cells_AreaShape_Perimeteron',
  'Cells_AreaShape_Solidityron',
  'Cells_AreaShape_Zernike_0_0',
  'Cells_AreaShape_Zernike_1_1',
  'Cells_AreaShape_Zernike_2_0',
  'Cells_AreaShape_Zernike_2_2',
  'Cells_AreaShape_Zernike_3_1',
  'Cells_AreaShape_Zernike_3_3',
  'Cells_AreaShape_Zernike_4_0',
  'Cells_AreaShape_Zernike_4_2',
  'Cells_AreaShape_Zernike_4_4',
  'Cells_AreaShape_Zernike_5_1',
  'Cells_AreaShape_Zernike_5_3',
  'Cells_AreaShape_Zernike_5_5',
  'Cells_AreaShape_Zernike_6_0',
  'Cells_AreaShape_Zernike_6_2',
  'Cells_AreaShape_Zernike_6_4',
  'Cells_AreaShape_Zernike_6_6',
  'Cells_AreaShape_Zernike_7_1',
  'Cells_AreaShape_Zernike_7_3',
  'Cells_AreaShape_Zernike_7_5',
  'Cells_AreaShape_Zernike_7_7',
  'Cells_AreaShape_Zernike_8_0',
  'Cells_AreaShape_Zernike_8_2',
  'Cells_AreaShape_Zernike_8_4',
  'Cells_AreaShape_Zernike_8_6',
  'Cells_Children_CytoplasmExpandedNuclei_Count',
  'Cells_Children_Cytoplasm_CountedNuclei_Count',
  'Cells_Children_LargeBODIPYObjects_CountCount',
  'Cells_Children_SmallBODIPYObjects_CountCount',
  'Cells_Correlation_Correlation_DNA_AGPntCount',
  'Cells_Correlation_Correlation_DNA_BODIPYount',
  'Cells_Correlation_Correlation_DNA_MitoPYount',
  'Cells_Correlation_Correlation_BODIPY_AGPount',
  'Cells_Correlation_Correlation_Mito_AGPGPount',
  'Cells_Correlation_Correlation_Mito_BODIPYunt',
  'Cells_Correlation_Costes_AGP_DNAto_BODIPYunt',
  'Cells_Correlation_Costes_AGP_BODIPYBODIPYunt',
  'Cells_Correlation_Costes_AGP_MitoPYBODIPYunt',
  'Cells_Correlation_Costes_DNA_AGPoPYBODIPYunt',
  'Cells_Correlation_Costes_DNA_BODIPYBODIPYunt',
  'Cells_Correlation_Costes_DNA_MitoPYBODIPYunt',
  'Cells_Correlation_Costes_BODIPY_AGPBODIPYunt',
  'Cells_Correlation_Costes_BODIPY_DNABODIPYunt',
  'Cells_Correlation_Costes_BODIPY_MitoODIPYunt',
  'Cells_Correlation_Costes_Mito_AGPitoODIPYunt',
];
