import { React, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Widget, addResponseMessage, addUserMessage } from 'react-chat-widget';
import defaultChannelIcon from './assets/LifeAnalyticsSteckerROGO-JP.jpg';
import avatarImg from '../../assets/images/avatar.png';
import mainApiService from '@/services/mainApiService';

import PropTypes from 'prop-types';
import SlackBot from 'slack';
import { load as emojiLoader, parse as emojiParser } from 'gh-emoji';
// Chat Functions
import {
  wasIMentioned,
  decodeHtml,
  postFile,
  getNewMessages,
  hasEmoji,
  hasAttachment,
  isSystemMessage,
  isAdmin,
} from './slackChat_func/chat-functions';
import { hooks, themes, utils, cacheChannelMap } from './slackChat_func';
// Slack API Funcs
import {
  getChannels,
  getUsers,
  getMessages,
  postMessage,
} from './slackChat_func/slack-utils';
import './ReactSlackChat.scss';
import OpenAI from 'openai';

// Utils
const { debugLog, arraysIdentical } = utils;

// Hooks
const { isHookMessage, execHooksIfFound } = hooks;

// Themes
const { changeColorRecursive } = themes;

// Cached Channel Map
const { getCachedChannelMap, saveChannelMap } = cacheChannelMap;

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const SupportChatGCP = (props) => {
  const user = useSelector((state) => state.auth.user);
  
  const [apiToken, setApiToken] = useState(process.env.REACT_APP_SLACKBOT_API_TOKEN);
  const [bot, setBot] = useState(new SlackBot({ token: apiToken }));
  const [botName, setBotName] = useState('support-ias-react-bot');
  const [channels, setChannels] = useState([
    {
      name: 'ias-support-chat',
      id: 'C04H9NKCKR6',
    },
  ]);
  const [defaultChannel, setDefaultChannel] = useState('ias-support-chat');
  const [hooks, setHooks] = useState([
    {
      /* My Custom Hook */
      id: 'getSystemInfo',
      action: () => Promise.resolve('MY SYSTEM INFO!'),
    },
  ]);
  const [failed, setFailed] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [knownMessageIds, setKnownMessageIds] = useState([]);
  // keep track of user threads for messaging in singleUserMode
  const [chatbox, setChatbox] = useState({
    active: false,
    channelActiveView: false,
    chatActiveView: false,
  });

  const [refreshTime, setRefreshTime] = useState(5000);
  var chatInitiatedTs = '';
  var activeChannel = {
    name: 'ias-support-chat',
    id: 'C04H9NKCKR6',
  };
  var activeChannelInterval = null;

  const TS_MAP = getCachedChannelMap({ channels: channels });

  const handleNewUserMessage = async (newMessage) => {
    // Now send the message throught the backend API
    //add messsage to slack channel
    postMyMessage(newMessage, {name: user.fullName, id: user.email, entity: 'Client'});
    //end
    let responseMessage = await generateChatResponse(newMessage);
    //postMyMessage(responseMessage, botName);
    // console.log("new response --->", responseMessage);
    //let responseMessage = "This is temp message"
    postMyMessage(responseMessage, {name: 'Life Analytics Support AI', id: botName, entity: 'AI'});
    addResponseMessage(responseMessage);
    openChatBox(null);
  };

  const generateChatResponse = async (payload) => {
    // const url = 'https://api.openai.com/v1/engines/davinci/completions';
    // const prompt = `User: ${message}\nBot: `;
    // const data = {
    //   prompt,
    //   max_tokens: 60,
    //   temperature: 0.7,
    //   n: 1,
    //   stop: '\n',
    // };
    // const headers = {
    //   'Content-Type': 'application/json',
    //   Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    // };
    // const response = await fetch(url, {
    //   method: 'POST',
    //   headers,
    //   body: JSON.stringify(data),
    // });
    // const { choices } = await response.json();
    // return choices[0].text.trim();

    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const system_message = `
    You are an expert in Image Processing Engineer with rich experience in microscopy image.
    `;

    const response = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo-16k',
      messages: [
        { role: 'system', content: system_message },
        { role: 'user', content: payload },
      ],
      temperature: 0,
    });

    // const { choices } = await response.json();

    //console.log(response)
    // console.log(choices)
    return response.choices[0].message.content;
  };

  const postMyMessage = async (message, data) => {
    try {
      const {success} = await mainApiService.post('/extras/slack', {...data, message});

      if (success) {
        
      } else {
        throw new Error('Failed to send message.');
      }
    } catch (error) {
    }
  }; 

  useEffect(() => {
    const loadLastMessages = async () => {
      try {
        const { success, messages } = await mainApiService.get(`/extras/support_messages/${user._id}/`);
  
        if (success) {
          const newMessageIds = [];
          
          // Use a local variable to work with the current knownMessageIds
          let currentKnownMessageIds = [...knownMessageIds]; 
  
          messages.forEach((msg) => {
            if (!currentKnownMessageIds.includes(msg._id)) {  // Check against the local variable
              if (msg.data.id === botName || msg.data.id === 'support') {
                addResponseMessage(msg.data.message, msg._id);
              } else {
                addUserMessage(msg.data.message, msg._id);
              }
              newMessageIds.push(msg._id);
              currentKnownMessageIds.push(msg._id);  // Add to local variable
            }
          });
  
          if (newMessageIds.length > 0) {
            // Update the state once with the updated IDs
            setKnownMessageIds(currentKnownMessageIds); 
          }
        } else {
          throw new Error('Failed to get messages.');
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    let timeout = null;
    if (user){ 
      loadLastMessages();
      timeout = setTimeout(() => setMessagesCount(1+messagesCount), 8000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [user?._id, messagesCount]);
  

  const goToChatView = (e, channel) => {
    // stop propagation so we can prevent any other click events from firing
    e.stopPropagation();
    // Close Chat box only if not already open
    if (chatbox.active) {
      activeChannel = channel;
      setChatbox({
        active: true,
        channelActiveView: false,
        chatActiveView: true,
      }).then(() => {
        if (activeChannelInterval) {
          clearInterval(activeChannelInterval);
        }
        // Focus input box
        const inputTextBox = document.getElementById('chat__input__text');
        inputTextBox.focus();
        // loadMessages(channel);
      });
      // Set this channel as active channel
    }
    return false;
  };

  const openChatBox = (e) => {
    // stop propagation so we can prevent any other click events from firing
    // e.stopPropagation();
    // persist click event to stopPropagation later
    // e.persist();
    // Open Chat box only if not already open
    // if (!chatbox.active) {
    setChatbox({
      active: true,
      channelActiveView: true,
      chatActiveView: false,
    });

    // Look to see if an active channel was already chosen...
    if (Object.keys(activeChannel).length > 0) {
      // If yes, load that chat view instead
      goToChatView(e, activeChannel);
    }

    // }
    return false;
  };

  const closeChatBox = (e) => {
    // stop propagation so we can prevent any other click events from firing
    e.stopPropagation();
    // Close Chat box only if not already open
    if (chatbox.active) {
      setChatbox({
        active: false,
        channelActiveView: false,
        chatActiveView: false,
      });
    }
    return false;
  };

  return (
    <>
      <Widget
        onClick={(e) => {
          openChatBox(e);
        }}
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={defaultChannelIcon}
        titleAvatar={defaultChannelIcon}
        profileClientAvatar={avatarImg}
        title="Support Chat"
        subtitle="ias-support-chat"
        senderPlaceHolder="Enter your message."
      />
    </>
  );
};

export default connect(mapStateToProps)(SupportChatGCP);
