import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MeasureHeader } from '@/constants/filters';
import { useSelector } from 'react-redux';
import { Button, MenuItem, Select } from '@mui/material';
import { readRemoteFile } from 'react-papaparse';
import store from '@/reducers';
import Slides from './Slides';
import { useElementSize } from 'usehooks-ts';
import { Typography } from 'antd';
import Stack from '@mui/material/Stack';

const AnalysisList = () => {
  const [items, setItems] = useState([]);
  const [classSettingValue, setClassSettingValue] = useState('S+R+G+B'); // Default value is 'S+R+G+B'
  const [selectedValues, setSelectedValues] = useState([]); // Initialize with empty array
  const experimentName = useSelector((state) => state.experiment.method);
  const [ref, { width }] = useElementSize();
  const [holeSelected, setHoleSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    const tempItems = [];

    MeasureHeader.map((item, index) => {
      if (index !== 0) {
        const tempItem = {};
        tempItem['id'] = index;
        tempItem['name'] = item;
        tempItems.push(tempItem);
      }
    });

    setItems(tempItems);
  }, []);

  const handleListItemClick = (e, number) => {
    setSelectedIndex(number);
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', e.target.id);
  };

  const handleChangeClassSetting = (e) => {
    const value = e.target.value;
    updateClassSetting(value);
  };

  const handleButtonClick = (value) => {
    // Check if the value is already selected
    if (selectedValues.includes(value)) {
      // If selected, remove it
      const newSelectedValues = selectedValues.filter((val) => val !== value);
      setSelectedValues(newSelectedValues);
      console.log(newSelectedValues.join('+'), 'value__+++');
      updateClassSetting(newSelectedValues.join('+'));
    } else {
      // If not selected, add it
      const newSelectedValues = [...selectedValues, value];
      setSelectedValues(newSelectedValues);
      console.log(newSelectedValues.join('+'), 'value__+++');
      updateClassSetting(newSelectedValues.join('+'));
    }
  };

  const updateClassSetting = (value) => {
    console.log(value, 'value__+++22');

    store.dispatch({
      type: 'set_selected_channel_in_report',
      content: value,
    });

    setClassSettingValue(value);
    localStorage.setItem('classSettingValue', value);
  };

  const selectedImageItemPath = useSelector(
    (state) => state.files.selectedThumbnailPathForVisual,
  );

  // Get the CSV path
  const getCsvPath = (path, color) => {
    let csvPath = '';

    if (classSettingValue === 'Overlay') {
      csvPath = path.split('timg')[0] + 'ome_300.csv';
    } else {
      let filenameSplitList = path.split('/');
      let len = filenameSplitList.length;
      let filename = filenameSplitList[len - 2];

      csvPath = path.split(filename)[0] + color + 'ashlar_output.ome_300.csv';
    }

    readRemoteFile(csvPath, {
      complete: (results) => {
        store.dispatch({
          type: 'set_selected_csv_data',
          content: results.data,
        });
      },
      error: (err) => {
        store.dispatch({
          type: 'set_selected_csv_data',
          content: null,
        });
      },
    });
  };

  const handleHoleSelected = () => {
    let flag = !holeSelected;
    setHoleSelected(flag);

    store.dispatch({
      type: 'set_report_tab_hole_selected_flag',
      content: flag,
    });
  };

  useEffect(() => {
    if (selectedImageItemPath !== undefined && selectedImageItemPath !== null) {
      getCsvPath(selectedImageItemPath, classSettingValue);
    }
  }, [classSettingValue, selectedImageItemPath]);
  useEffect(() => {
  handleButtonClick(selectedValues?'':'S+R+G+B')

  }, []);


  return (
    <>
      <div className="visual-analysis-items">
        {/* Button Group for Class Settings */}
        {/* <Stack direction="row" spacing={0} sx={{ mb: 2, p: 0, m: 0 }}>
  {['S', 'R', 'G', 'B'].map((value) => (
    <Button
      key={value}
      variant={selectedValues.includes(value) ? '#cbcacb' : '#ffffff'}
      onClick={() => handleButtonClick(value ? value : 'S+R+G+B')}
      sx={{
        clipPath: 'polygon(6% 0%, 0% 0%, 57% 0%, 65% 100%, 0% 100%)', // Creates the angled edge
        padding: '1px', // Remove padding
        margin: '-10px', // Remove margin
        // minWidth: 'auto', // Remove default minWidth
        width: '60%', // Adjust width as needed
        marginTop:'0.1rem'
      }}
    >
      {value}
    </Button>
  ))}
</Stack> */}
<Stack direction="row" spacing={0} sx={{ mb: 2, p: 0, m: 0 }}>
  {['S', 'R', 'G', 'B'].map((value) => (
    <div
      key={value}
      style={{
        clipPath: 'polygon(6% 0%, 0% 0%, 57% 0%, 78% 100%, 0% 100%)', // Same clip-path as the button for consistency
        backgroundColor: '#000000', // Border color
        
        padding: '2px', // Controls the border width
        display: 'inline-block', // Ensures the wrapper fits around the button
        // lineHeight: 0, // Prevents extra space from affecting layout
        // lineHeight: selectedValues.includes(value) ?  '2px solid #FFFFFF':'0' , // Conditional bottom border

        margin: '-10px', // Remove margin
        width: '70%', // Adjust width as needed
        borderBottom: selectedValues.includes(value) ? '2px solid #FFFFFF' : '2px solid #FFFFFF', // Conditional bottom border
        marginTop: '0.1rem',
        // borderBottom: selectedValues.includes(value) ? '2px solid white' : '', // White if selected, #cbcacb if not
        marginRight:"-1.4rem"

      }}
    >
      <Button
        variant="contained"
       onClick={() => handleButtonClick(value ? value : 'S+R+G+B')}

        sx={{
          clipPath: 'polygon(6% 0%, 0% 0%, 57% 0%, 78% 100%, 0% 100%)', // Keeps the angled edge of the button
          padding: '0', // Adjust padding to fit inside border without extra space
          width: '100%', // Make the button width match the wrapper
        paddingRight:'18px',        

          backgroundColor: selectedValues.includes(value) ? '#ffffff' : '#cbcacb', // White if selected, #cbcacb if not
          borderBottom: selectedValues.includes(value) ? '2px solid #FFFFFF' : '', // White if selected, #cbcacb if not

          color: '#000000', // Black text
          '&:hover': {
            backgroundColor: selectedValues.includes(value) ? '#ffffff' : '#948a84', // Slightly darker shade on hover for unselected
          },
        }}
      >
        {value}
      </Button>
    </div>
  ))}
</Stack>


        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <List component="nav" aria-label="main mailbox folders">
            {items.map((item) => (
              <ListItemButton
                key={`item-${item.id}`}
                selected={selectedIndex === item.id}
                draggable={true}
                id={item.id}
                onClick={(e) => handleListItemClick(e, item.id)}
                onDragStart={handleDragStart}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </div>

      <div
        className="visual-sidebar-filter"
        style={{ minWidth: '100%', minHeight: '300px' }}
        ref={ref}
      >
        <Typography style={{ alignItems: 'center', display: 'flex' }}>
          Single Slide
        </Typography>
        <Button onClick={handleHoleSelected}>
          <Slides
            width={width}
            count={1}
            showHole={holeSelected}
            areaPercentage={100}
          />
        </Button>
      </div>
    </>
  );
};

export default AnalysisList;
