export const FILE_TYPES = [
  '.1sc',
  '.2',
  '.2fl',
  '.3',
  '.4',
  '.acff',
  '.afi',
  '.afm',
  '.aim',
  '.al3d',
  '.ali',
  '.am',
  '.amiramesh',
  '.apl',
  '.arf',
  '.avi',
  '.bif',
  '.bin',
  '.bip',
  '.bmp',
  '.btf',
  '.c01',
  '.cfg',
  '.ch5',
  '.cif',
  '.cr2',
  '.crw',
  '.csv',
  '.cxd',
  '.czi',
  '.dat',
  '.dcm',
  '.dib',
  '.dicom',
  '.dm2',
  '.dm3',
  '.dm4',
  '.dti',
  '.dv',
  '.eps',
  '.epsi',
  '.exp',
  '.fdf',
  '.fff',
  '.ffr',
  '.fits',
  '.flex',
  '.fli',
  '.frm',
  '.gel',
  '.gif',
  '.grey',
  '.h5',
  '.hdf',
  '.hdr',
  '.hed',
  '.his',
  '.htd',
  '.html',
  '.hx',
  '.i2i',
  '.ics',
  '.ids',
  '.im3',
  '.img',
  '.ims',
  '.inr',
  '.ipl',
  '.ipm',
  '.ipw',
  '.j2k',
  '.jp2',
  '.jpf',
  '.jpg',
  '.jpk',
  '.jpx',
  '.klb',
  '.l2d',
  '.labels',
  '.lei',
  '.lif',
  '.liff',
  '.lim',
  '.lms',
  '.lsm',
  '.map',
  '.mdb',
  '.mea',
  '.mnc',
  '.mng',
  '.mod',
  '.mov',
  '.mrc',
  '.mrcs',
  '.mrw',
  '.msr',
  '.mtb',
  '.mvd2',
  '.naf',
  '.nd',
  '.nd2',
  '.ndpi',
  '.ndpis',
  '.nef',
  '.nhdr',
  '.nii',
  '.nii.gz',
  '.nrrd',
  '.obf',
  '.obsep',
  '.oib',
  '.oif',
  '.oir',
  '.ome',
  '.ome.btf',
  '.ome.tf2',
  '.ome.tf8',
  '.ome.tif',
  '.ome.tiff',
  '.ome.xml',
  '.par',
  '.pbm',
  '.pcoraw',
  '.pcx',
  '.pds',
  '.pgm',
  '.pic',
  '.pict',
  '.png',
  '.pnl',
  '.ppm',
  '.pr3',
  '.ps',
  '.psd',
  '.qptiff',
  '.r3d',
  '.raw',
  '.rcpnl',
  '.rec',
  '.res',
  '.scn',
  '.sdt',
  '.seq',
  '.sif',
  '.sld',
  '.sm2',
  '.sm3',
  '.spc',
  '.spe',
  '.spi',
  '.st',
  '.stk',
  '.stp',
  '.svs',
  '.sxm',
  '.tf2',
  '.tf8',
  '.tfr',
  '.tga',
  '.tif',
  '.tiff',
  '.tnb',
  '.top',
  '.v',
  '.vff',
  '.vms',
  '.vsi',
  '.vws',
  '.wat',
  '.wlz',
  '.wpi',
  '.xdce',
  '.xml',
  '.xqd',
  '.xqf',
  '.xv',
  '.xys',
  '.zfp',
  '.zfr',
  '.zvi',
];
