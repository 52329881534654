import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { AreaSelector } from '@bmunozg/react-image-area';

const ROISelectionModal = ({ visible, onClose, imageUrl, onFinish }) => {
  const [areas, setAreas] = useState([]);
  const imageRef = useRef(null);

  const handleFinish = async () => {
    const croppedImages = await cropImages();
    onFinish(croppedImages);  // Pass the cropped image data to onFinish
    onClose();
  };

  const cropImages = () => {
    return new Promise((resolve) => {
      let rois = [];
      const image = imageRef.current;
      const base_canvas = document.createElement('canvas');
      base_canvas.width = image.width;
      base_canvas.height = image.height;

      const ctx = base_canvas.getContext('2d');
      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0, image.width, image.height);

      // Convert the canvas content into a Blob and create a URL for it
      base_canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        rois.push({ url, blob });
      }, 'image/png', 1);
      
      let blobPromises = areas.map((rect) => {
        return new Promise((resolveBlob) => {
          const canvas = document.createElement('canvas');
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          canvas.width = rect.width;
          canvas.height = rect.height;
          const ctx = canvas.getContext('2d');
      
          const pixelRatio = window.devicePixelRatio;
          canvas.width = rect.width * pixelRatio;
          canvas.height = rect.height * pixelRatio;
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = 'high';
      
          ctx.drawImage(
            image,
            rect.x * scaleX,
            rect.y * scaleY,
            rect.width * scaleX,
            rect.height * scaleY,
            0,
            0,
            rect.width,
            rect.height,
          );

          // Create a Blob from the cropped area
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            rois.push({ url, blob });
            resolveBlob(); // Resolve once the blob is created
          }, 'image/png', 1);
        });
      });

      // Wait for all blobs to be processed
      Promise.all(blobPromises).then(() => {
        resolve(rois);
      });
    });
  };


  const customRender = (areaProps) => {
    if (!areaProps.isChanging) {
      return <div key={areaProps.areaNumber} style={{ position: 'relative' }}>{areaProps.areaNumber}</div>;
    }
  };

  return (
    <div className='card'>
      {visible ?
        <div className='card-body' style={{ width: '100%', height: 500, overflow: 'scroll' }}>
          <AreaSelector
            areas={areas}
            // maxAreas={1}
            onChange={(as) => setAreas(as)}
            customAreaRenderer={customRender}
            globalAreaStyle={{
              border: '3.5px dashed blue',
              backgroundColor: 'lightblue',
              opacity: '0.5',
            }}
          >
            {!imageUrl.includes('undefined') ? <img
              ref={imageRef}
              src={imageUrl}
              alt="ROI"
              style={{ width: '100%', height: '100%' }}
            ></img> : <p>Preview image not loaded ...</p>}
          </AreaSelector>
          <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <Button key="finish" type="primary mr-3" onClick={handleFinish}>
              Add ROIs
            </Button>
            <Button type="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div> : null}
    </div>
  );
};

export default ROISelectionModal;
